// Grid settings
$enable-flex:           true;
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #009bd9;
$white:                 #fff;
$light-blue:            #7fdbff;
$medium-blue:           #009bd9;
$dark-blue:             #03223e;
$egg-shell:             #ffefb4;
$seafoam:               #51c2c6;

// Type

$font-base:             1rem;
$font-xsmall:           0.75rem;
$font-small:            0.85rem;
$font-medium:           0.875rem;
$font-large:            1.125rem;
$font-xlarge:           1.3125rem;

$font-h1:               2rem;
$font-h2:               2.6rem;
$font-h3:               1.4rem;
$font-h4:								$font-xlarge;

@font-face {
    font-family: 'Affogato-Black';
    src: url('../fonts/Affogato-Black.eot');
    src: url('../fonts/Affogato-Black.eot?#iefix') format('embedded-opentype'),
    	 url('../fonts/Affogato-Black.woff') format('woff'),
         url('../fonts/Affogato-Black.woff2') format('woff2'),
         url('../fonts/Affogato-Black.ttf')  format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Affogato-Bold';
    src: url('../fonts/Affogato-Bold.eot');
    src: url('../fonts/Affogato-Bold.eot?#iefix') format('embedded-opentype'),
    	 url('../fonts/Affogato-Bold.woff') format('woff'),
         url('../fonts/Affogato-Bold.woff2') format('woff2'),
         url('../fonts/Affogato-Bold.ttf')  format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Affogato-Medium';
    src: url('../fonts/Affogato-Medium.eot');
    src: url('../fonts/Affogato-Medium.eot?#iefix') format('embedded-opentype'),
    	 url('../fonts/Affogato-Medium.woff') format('woff'),
         url('../fonts/Affogato-Medium.woff2') format('woff2'),
         url('../fonts/Affogato-Medium.ttf')  format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Affogato-Regular';
    src: url('../fonts/Affogato-Regular.eot');
    src: url('../fonts/Affogato-Regular.eot?#iefix') format('embedded-opentype'),
    	 url('../fonts/Affogato-Regular.woff') format('woff'),
         url('../fonts/Affogato-Regular.woff2') format('woff2'),
         url('../fonts/Affogato-Regular.ttf')  format('truetype');
}
@font-face {
    font-family: 'Affogato-Light';
    src: url('../fonts/Affogato-Light.eot');
    src: url('../fonts/Affogato-Light.eot?#iefix') format('embedded-opentype'),
    	 url('../fonts/Affogato-Light.woff') format('woff'),
         url('../fonts/Affogato-Light.woff2') format('woff2'),
         url('../fonts/Affogato-Light.ttf')  format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Ale-Pro';
    src: url('../fonts/ale_pro-webfont.ttf')  format('truetype');
    font-weight: normal;
    font-style: normal;
}

$freight-sans:         "freight-sans-pro", sans-serif;
$ale-pro:              'Ale-Pro';
$affogato-black:       'Affogato-Black';
$affogato-bold:        'Affogato-Bold';
$affogato-medium:      'Affogato-Medium';
$affogato-regular:     'Affogato-Regular';
$affogato-light:       'Affogato-Light';

$screen-xs:             576px;
$screen-sm:             768px;
$screen-md:             992px;
$screen-lg:             1200px;

// Padding
$padding-small:         32px;
$padding-medium:        50px;
$padding-large:         100px;
