/* Frontpage */

.hero{
	height: 100vh;
	background: url(../images/background-mobile.jpg) no-repeat center center;
	background-size: cover;
	text-align: center;
	background-attachment: scroll;
	position: relative;
	@media (min-width: $screen-xs) {
		background: url(../images/background-top.png) no-repeat center center;
		background-size: cover;
		background-attachment: fixed;
	}
	.foreground{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: url(../images/foreground-textured.png) no-repeat center center;
		background-size: cover;
		display: none;
		@media (min-width: $screen-xs) {
			display: inline-block;
		}
	}
}
.centered{	
  font-size: 21px;
  position: relative;
  z-index: 1;
  img{
	  display: inline-block;
	  max-width: 200px;
	  height: auto;
	  @media (min-width: $screen-sm) {
		  max-width: 310px;
	  }
  }
}
.gallery{
	.carousel-cell{
		width: 100%;
		left: 0;
		top: 0;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		height: 368px;
		@media (min-width: $screen-xs) {
			height: 60vh;
		}
		@media (min-width: $screen-sm) {
			height: 85vh;
		}
	}
	.flickity-prev-next-button{
		display: none;
	}
	.flickity-page-dots{
		bottom: 25px;
		.dot{
			width: 16px;
			height: 16px;
			background: $white;
		}
	}	
}
.main{
	padding-left: 0;
	padding-right: 0;
}
.nav{
	padding: $padding-medium 0;
	position: relative;
	font-family: $freight-sans;
	li{
		display: inline-block;
		padding: 0 15px;
		text-transform: uppercase;
		font-size: $font-base;
		font-weight: 700;
		letter-spacing: 0.05em;
		@media (min-width: $screen-xs) {
			padding: 0 25px;
			font-size: $font-xlarge;
		}
		a{
			color: $white;
			&.chownow-order-online {
				background: $medium_blue;
				color: $egg-shell;
				border-radius: 8px;
				padding: 6px 14px;
				&:hover {
					background: $egg-shell;
					color: $medium_blue;
				}
			}
			&:hover{
				text-decoration: underline;
			}
		}
	}
	.menu-socials{
		position: absolute;
		left: 0;
		display: none;
		@media (min-width: $screen-sm) {
			display: inline-block;
		}
		.fa-stack-1x{
			color: $light-blue;
		}
		a{
			&:hover{
				text-decoration: none;
			}
		}
	}	
}
.intro{
	background: $medium_blue;
	color: $white;
}
.menu{
	color: $dark-blue;
	background: $egg-shell;
	font-size: $font-base;
	padding-bottom: $padding-large;
	h5{
		font-weight: 700;
		margin-bottom: 0;
		font-family: $freight-sans;
		span{
			color: $medium-blue;
			font-weight: 800;
			margin-left: 4px;
			font-size: $font-medium;
		}
	}
	.menu-section{
		background: $medium_blue;
		color: $egg-shell;
		font-size: $font-large;
		font-weight: 700;
		text-transform: uppercase;
		border-radius: 8px;
		padding: 6px 10px;
		margin: 20px 0;
		font-family: $freight-sans;
	}	
	.single-menu-item{
		margin-bottom: 20px;
	}
}
.contact{
	color: $white;
	background: url(../images/contact-bg.jpg);
	position: relative;
	padding: $padding-large 0;
	.cloud-one{
		position: absolute;
		top: -85px;
		left: -6px;
		z-index: 1;
		width: 298px;
		height: 133px;
		transition: opacity .6s ease-in-out, left .4s ease-in-out;
		opacity: 0;
		&.active{
			opacity: 1;
			left: 0;
		}
	}
	.cloud-two{
		position: absolute;
		top: 0;
		right: -85px;
		z-index: 1;
		transition: opacity .6s ease-in-out, right .4s ease-in-out;
		opacity: 0;
		width: 193px;
		height: 84px;
		&.active{
			opacity: 1;
			right: -80px;
		}
	}
	.contact-col{
		position: relative;
	}
	a{
		color: $egg-shell;
	}
	.hours{
		margin-top: 32px;
		text-transform: uppercase;
		font-weight: 800;
		font-size: $font-xlarge;
		span{
			float: right;
		}
	}
	.socials{
		margin-top: 15px;
		li{
			display: inline-block;
			margin-right: 5px;
		}
	}
	.map{
		margin-top: 25px;
		float: right;
		@media (min-width: $screen-sm) {
			margin-top: 0;
		}
	}
	.fa-stack-1x{
		color: $seafoam;
	}	
	.fa-stack-2x{
		color: $white;
	}
}