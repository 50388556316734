/* Global Styles */
html{
	font-size: 16px;
}
body{
	font-family: $affogato-medium;
  -o-animation: fadein 2s;
  animation: fadein 2s;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-size: $font-base;
  line-height: 1.4;
  overflow-x: hidden;
  @media (min-width: $screen-xs) {
	 font-size: $font-large; 
  }
}
@keyframes fadein
{
    from
    {
        opacity: 0;
    }
    to
    {
        opacity: 1;
    }
}
@-webkit-keyframes fadein
{
    from
    {
        opacity: 0;
    }
    to
    {
        opacity: 1;
    }
}
@-o-keyframes fadein
{
    from
    {
        opacity: 0;
    }
    to
    {
        opacity: 1;
    }
}
img{
	max-width: 100%;
	height: auto;
}
*:focus {
  outline: none;
  text-decoration: none;
}
section{
	padding: $padding-medium 0;
	@media (min-width: $screen-sm) {
		padding: $padding-large 0;
	}
}
h1{
	text-transform: uppercase;
	font-family: $ale-pro;
	margin-bottom: 30px;
}
h2{
	font-family: $freight-sans;
	font-weight: 900;
	font-size: $font-h3;
	@media (min-width: $screen-xs) {
		font-size: $font-h2;
	}
}
h5{
	font-size: $font-h4;
}
ul{
	list-style: none;
	padding: 0;
	margin: 0;
}
.mobile-wrap{
	overflow: hidden;
}